import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import { PDFDocument } from 'pdf-lib';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup, InputGroup, Modal, ProgressBar, Radio } from 'react-bootstrap';

import DealAction from '@core/enums/DealAction';
import Attachment, {
  ACCEPTED_TYPES,
  ATTACHMENT_TYPE,
  MAX_SIZE,
  STORAGE_TYPES,
  VERSION_TYPES,
} from '@core/models/Attachment';
import Deal from '@core/models/Deal';
import DealVersion, { strip } from '@core/models/DealVersion';
import User from '@core/models/User';
import { SAVEABLE_VAR_FIELDS, ValueType } from '@core/models/Variable';
import { MERGE_TYPE } from '@core/models/Version';
import { DateFormatter, Dt, Stopwatch, arrayToBase64, base64ToArray, dt } from '@core/utils';

import { Alert, Button, DayTime, Dropdown, MenuItem } from '@components/dmp';

import DealStatusSelector from '@components/deal/DealStatusSelector';
import FileUp from '@components/editor/FileUp';
import API from '@root/ApiClient';
import Fire from '@root/Fire';

const FILE_OPTIONS = [
  {
    type: ATTACHMENT_TYPE.STORAGE,
    info: `Supporting files related to this ${dt}`,
    disabled: () => false,
    title: _.upperFirst(ATTACHMENT_TYPE.STORAGE),
  },
  {
    type: ATTACHMENT_TYPE.VARIABLE,
    info: `Update an image variable on this ${dt}`,
    disabled: () => false,
    title: _.upperFirst(ATTACHMENT_TYPE.VARIABLE),
  },
  {
    type: ATTACHMENT_TYPE.VERSION,
    info: `Updated version of this ${dt}`,
    disabled: (isStorageOnly) => isStorageOnly,
    title: _.upperFirst(ATTACHMENT_TYPE.VERSION),
  },
];

@autoBindMethods
export default class AttachmentUploader extends Component {
  static defaultProps = {
    item: null,
    onClose: _.noop,
  };

  static propTypes = {
    du: PropTypes.object,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    droppedFile: PropTypes.instanceOf(File),
    item: PropTypes.oneOfType([PropTypes.instanceOf(DealVersion), PropTypes.instanceOf(Attachment)]),
    deal: PropTypes.instanceOf(Deal).isRequired,
    user: PropTypes.instanceOf(User).isRequired,
    history: PropTypes.object.isRequired,
    attachmentType: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      error: null,
      errorInfo: null,
      progress: null,
      file: null,
      title: '',
      description: '',
      dealStatus: null,
      du: null,
      dateType: 'file',
      lastModified: null,
      attachmentType: null,
      variable: null,
    };

    this.refCustomDate = createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    this.populate();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const { item, show } = this.props;

    if (show && !prevProps.show) {
      this.populate(item);
    }
  }

  get isNew() {
    return !this.props.item;
  }

  get isReady() {
    const { file, dealStatus, du, error, title, attachmentType, variable } = this.state;

    if (!attachmentType) return false;

    if (this.isNew) {
      switch (attachmentType) {
        case ATTACHMENT_TYPE.VERSION:
          return file && dealStatus && du && !error;
        case ATTACHMENT_TYPE.STORAGE:
          return file && !error && title;
        case ATTACHMENT_TYPE.VARIABLE:
          return !error && variable;
        default:
          return false;
      }
    } else {
      // see if something changed; disallow empty file title updates
      switch (attachmentType) {
        case ATTACHMENT_TYPE.VERSION:
          return !error;
        case ATTACHMENT_TYPE.STORAGE:
          return !error && title.trim();
        case ATTACHMENT_TYPE.VARIABLE:
          return !error && variable;
        default:
          return false;
      }
    }
  }

  get isPDF() {
    return _.get(this.state.file, 'type') === ACCEPTED_TYPES.PDF.mime;
  }

  get isWord() {
    return _.get(this.state.file, 'type') === ACCEPTED_TYPES.WORD.mime;
  }

  get isVersion() {
    return this.state.attachmentType === ATTACHMENT_TYPE.VERSION;
  }

  get isStorage() {
    return this.state.attachmentType === ATTACHMENT_TYPE.STORAGE;
  }

  get isStorageOnly() {
    const { extension } = this;
    if (extension && _.find(STORAGE_TYPES, { extension }) && !_.find(VERSION_TYPES, { extension })) {
      return true;
    }
    return false;
  }

  get title() {
    const { saving } = this.state;
    const typeName = this.isVersion ? 'version' : 'attachment';

    if (this.isNew) {
      return saving ? 'Uploading' : `Upload new ${typeName}`;
    } else {
      return `Update ${typeName} metadata`;
    }
  }

  get extension() {
    const { item } = this.props;
    const { file } = this.state;

    if (item instanceof Attachment) {
      return item.extension;
    } else if (file) {
      return file.name.split('.').pop();
    }
    return null;
  }

  get imageVariables() {
    const { deal } = this.props;

    return _.filter(deal.variables, { valueType: ValueType.IMAGE });
  }

  populate(item) {
    const { deal } = this.props;

    // Always clear first before populating, for subsequent use (e.g., multiple consecutive uploads/updates)
    this.reset();

    // If we're updating an existing version, pull metadata from that
    if (item instanceof DealVersion) {
      this.setState({
        dealStatus: item.dealStatus,
        du: deal.getUserByID(item.owner),
        description: item.description || '',
        attachmentType: ATTACHMENT_TYPE.VERSION,
        lastModified: item.date,
      });
      this.updateDate({ date: new Date(parseInt(item.dateCreated)) });
    } else if (item instanceof Attachment) {
      this.setState({
        title: item.title || '',
        description: item.description || '',
        attachmentType: ATTACHMENT_TYPE.STORAGE,
        date: item.date,
      });
    }
    // Otherwise (new upload) there are a few things we can populate from the Deal
    else {
      this.setState({
        dealStatus: deal.info.status,
        du: deal.currentDealUser,
      });
    }
  }

  reset() {
    if (this._isMounted) {
      this.setState({
        saving: false,
        error: null,
        errorInfo: null,
        progress: null,
        file: null,
        title: '',
        description: '',
        du: null,
        dealStatus: null,
        attachmentType: this.props.attachmentType || null,
      });
    }
  }

  async onParse(parsed, file, arrayBuffer) {
    const { deal } = this.props;

    if (!file) return this.reset();

    await this.setState({
      file,
      arrayBuffer,
      title: file ? strip(file.name) : '',
      lastModified: new Date(file.lastModified),
      dateType: 'file',
      dealStatus: deal.info.status,
    });

    // Ensure that date enforcement happens when a new file is selected (block DealVersions older than original)
    this.updateDate({ date: new Date(file.lastModified) });

    // Using await above ensures that file is in state so that we can now use extension check
    // If dropped file is acceptable as STORAGE but not as a VERSION, then we know what type to use
    if (
      this.extension &&
      _.find(STORAGE_TYPES, { extension: this.extension }) &&
      !_.find(VERSION_TYPES, { extension: this.extension })
    ) {
      this.setState({ attachmentType: ATTACHMENT_TYPE.STORAGE });
    }

    if (file.size > MAX_SIZE) {
      this.setState({
        error: 'File is too large. Attachments are limited to 50MB.',
        errorInfo: null,
      });
    }
  }

  async onChangeDateType(e) {
    const { file } = this.state;
    const dateType = e.target.value; //file or custom
    await this.setState({ dateType });

    if (dateType === 'file') {
      if (file) this.updateDate({ date: new Date(file.lastModified) });
    } else {
      // When switching BACK to custom date, ensure that we pull whatever is selected from component's state
      // Otherwise it only triggers on change
      const customDate = _.get(this.refCustomDate, 'current.state.date');
      if (customDate) this.updateDate({ date: customDate });
    }
  }

  async onChangeAttachmentType(attachmentType) {
    const { file } = this.state;

    // This should not be possible, as we're only allowing type selection once a file is present
    if (!file) return;

    // Use await so that the isVersion getter evaluates correctly inside updateDate below
    await this.setState({ attachmentType, dateType: 'file' });

    if (attachmentType === ATTACHMENT_TYPE.VERSION) {
      this.updateDate({ date: new Date(file.lastModified) });
    }
  }

  updateDate({ date }) {
    const { deal } = this.props;
    const original = deal.originalVersion;
    const newState = { lastModified: date };
    if (this.isVersion && original && date < original.date) {
      newState.error = `This version's date can not be prior to the original (${this.displayDate(original.date)})`;
    } else {
      newState.error = null;
    }
    this.setState(newState);
  }

  async tryUpload() {
    const { arrayBuffer, file } = this.state;

    // PDF
    if (file && file.type === ACCEPTED_TYPES.PDF.mime) {
      // We are currently not supporting encrypted PDFs
      // Attempt to open it for editing, if it's encrypted, it'll fail and we'll tell
      // the user to decrypt it (until we support PDF decryption)
      try {
        const pdfBytes = new Uint8Array(arrayBuffer);
        await PDFDocument.load(pdfBytes);
      } catch (err) {
        this.setState({
          error: 'Encrypted PDF files are not supported on Outlaw.',
          errorInfo: (
            <div>
              To upload this file, remove its encryption first.{' '}
              <a
                href="https://filevine.zendesk.com/knowledge/articles/17344599317531/en-us?brand_id=4415356259995"
                target="_blank"
                rel="noreferrer"
              >
                Learn how
              </a>
            </div>
          ),
        });
        return;
      }
    }

    try {
      await this.upload();
    } catch (error) {
      this.setState({ error: _.get(error, 'message', 'An error occurred') });
    }
  }

  async update() {
    const { item, onClose } = this.props;
    const { dealStatus, du, description, title } = this.state;

    const lastModified = this.state.lastModified || _.get(this.refCustomDate, 'current.state.date');

    await this.setState({ saving: true });

    if (this.isStorage) {
      item.title = title.trim();
      item.description = description.trim() || null;
      item.date = lastModified;
      await Fire.saveAttachment(item);
    } else if (this.isVersion) {
      item.dealStatus = dealStatus;
      item.owner = du.uid;
      item.description = description || null;
      item.dateCreated = lastModified.getTime().toString();
      await Fire.saveDealVersion(item);
    }

    this.reset();
    onClose();
  }

  async upload() {
    const { deal, onClose, user, history } = this.props;
    const { du, title, dealStatus, saving, arrayBuffer, file, description, lastModified, attachmentType, variable } =
      this.state;
    // Avoid duplicate import/upload attempts; ensure we have all data/selections necessary to proceed!

    if (saving || !this.isReady) return;

    let attachment, attachmentBytes, wordAttachment, wordBytes, success, newVersion;
    const dateCreated = lastModified.getTime().toString();

    const sw = new Stopwatch('UPLOADER');

    switch (file.type) {
      case ACCEPTED_TYPES.PDF.mime:
      case ACCEPTED_TYPES.PNG.mime:
      case ACCEPTED_TYPES.JPG.mime:
      case ACCEPTED_TYPES.TXT.mime:
      case ACCEPTED_TYPES.XLSX.mime:
      case ACCEPTED_TYPES.JSON.mime:
        // Create attachment (which includes file upload)
        await this.setState({ saving: true, progress: { current: 1, total: 2, description: 'Uploading file' } });
        attachment = new Attachment(
          {
            extension: this.extension,
            title,
            // Only store Attachment descriptions in Attachment model if they're simple Attachments (Versions have a description field)
            description: !this.isVersion && description.trim() ? description.trim() : null,
            attachmentType,
            date: !this.isVersion ? lastModified : null,
          },
          deal
        );

        attachmentBytes = new Uint8Array(arrayBuffer);
        const newAttachment = await Fire.saveAttachment(attachment, attachmentBytes);
        await API.call('syncDealPermissions', { dealID: deal.dealID });
        sw.step('Saved Attachment');

        if (attachmentType === ATTACHMENT_TYPE.VARIABLE) {
          if (variable) {
            const saveableVariable = _.pick(variable, SAVEABLE_VAR_FIELDS);
            const { key, bucketPath } = newAttachment;
            const downloadURL = await Fire.storage.ref(bucketPath).getDownloadURL();
            saveableVariable.value = { key, downloadURL };
            //If its legacy or the image has not been set yet just update the value with the new attachment.
            if (typeof variable.value === 'string' || !variable.value) {
              await Fire.saveVariableDefinition(deal, saveableVariable);
            } else {
              //save the attachment on the variable and delete the old attachment.
              const imageAttachment = _.find(deal.attachments, { key: variable.value.key });
              await Fire.deleteAttachment(imageAttachment);
              await Fire.saveVariableDefinition(deal, saveableVariable);
            }
          }
        }

        if (attachmentType === ATTACHMENT_TYPE.VERSION) {
          // Now create DealVersion which references the new Attachment
          await this.setState({ progress: { current: 2, total: 2, description: 'Generating file Version' } });
          newVersion = await Fire.saveDealVersion(
            new DealVersion(
              {
                owner: du.uid,
                pdfKey: attachment.key,
                dateCreated,
                dealStatus,
                description: description.trim() || null,
              },
              deal
            )
          );
          sw.step('Saved DealVersion');
        }
        success = true;
        break;

      case ACCEPTED_TYPES.WORD.mime:
        wordBytes = new Uint8Array(arrayBuffer);

        if (attachmentType === ATTACHMENT_TYPE.VERSION) {
          await this.setState({
            saving: true,
            progress: { current: 1, total: 4, description: 'Converting docx to pdf for file preview' },
          });
          // Convert the docx to PDF for viewing and store that too
          const pdfBase64 = await API.call('convertDOCXtoPDF', { docx: arrayToBase64(wordBytes) });
          sw.step('Converted to PDF');

          // Now we've got raw data for both the source docx and the converted PDF -- save both!
          await this.setState({ progress: { current: 2, total: 4, description: 'Uploading PDF' } });
          attachment = new Attachment(
            { extension: ACCEPTED_TYPES.PDF.extension, title, attachmentType: ATTACHMENT_TYPE.VERSION },
            deal
          );
          attachmentBytes = base64ToArray(pdfBase64);
          await Fire.saveAttachment(attachment, attachmentBytes);
          sw.step('Saved PDF Attachment');

          await this.setState({ progress: { current: 3, total: 4, description: 'Uploading DOCX' } });
          wordAttachment = new Attachment(
            { extension: ACCEPTED_TYPES.WORD.extension, title, attachmentType: ATTACHMENT_TYPE.VERSION },
            deal
          );
          await Fire.saveAttachment(wordAttachment, wordBytes);
          sw.step('Saved DOCX Attachment');

          // Now we've got both attachment keys; save it as an automatic version on the Deal
          await this.setState({ progress: { current: 4, total: 4, description: 'Generating file Version' } });
          newVersion = await Fire.saveDealVersion(
            new DealVersion(
              {
                owner: du.uid,
                pdfKey: attachment.key,
                docxKey: wordAttachment.key,
                dateCreated,
                dealStatus,
                description: description.trim() || null,
              },
              deal
            )
          );

          sw.step('Saved DealVersion');
        } else {
          await this.setState({ saving: true, progress: { current: 1, total: 2, description: 'Uploading file' } });
          wordAttachment = new Attachment(
            {
              extension: ACCEPTED_TYPES.WORD.extension,
              title,
              description: description.trim() || null,
              attachmentType: ATTACHMENT_TYPE.STORAGE,
              date: lastModified,
            },
            deal
          );
          await Fire.saveAttachment(wordAttachment, wordBytes);
          sw.step('Saved DOCX Attachment');
        }
        success = true;
        break;

      default:
        break;
    }

    // If we successfully uploaded a new version, log event and cleanup
    if (success) {
      if (attachmentType === ATTACHMENT_TYPE.VERSION) {
        Fire.addActivity(deal, user, DealAction.CREATE_VERSION, newVersion.key);
        this.reset();
        history.push(newVersion.deepLink);
        onClose(attachment);
      } else {
        this.reset();
        onClose(attachment);
      }
    }
  }

  onClose() {
    if (this.canClose) {
      this.reset();
      this.props.onClose();
    }
  }

  selectVariable(variableID) {
    const variable = _.find(this.imageVariables, { name: variableID });
    this.setState({ variable });
  }

  renderSaving() {
    const progress = _.get(this.state, 'progress', { current: 0, total: 1, description: '' });
    const percent = (progress.current / progress.total) * 100;

    return (
      <Modal.Body>
        <div className="saving">
          <ProgressBar bsStyle="info" now={percent} />
          <div className="details">
            <div className="step">
              {progress.current} of {progress.total}
            </div>
            <div className="description">{progress.description}</div>
          </div>
        </div>
      </Modal.Body>
    );
  }

  renderError() {
    const { error, errorInfo } = this.state;

    return (
      <>
        <Modal.Body>
          <div className="saving error">
            <Alert bsStyle="danger">
              <strong>Upload failed</strong> {error}
            </Alert>
            {errorInfo}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.reset}>Try Again</Button>
        </Modal.Footer>
      </>
    );
  }

  displayDate(date) {
    return `${DateFormatter.ymd(date, '-')} @ ${DateFormatter.time(date)}`;
  }

  get canClose() {
    const { saving, error } = this.state;
    return !saving || !!error;
  }

  get workflowStep() {
    const { deal } = this.props;
    const { dealStatus } = this.state;
    const idx = _.findIndex(deal.workflow.steps, { key: dealStatus });
    return idx > -1 ? deal.workflow.steps[idx] : deal.workflow.steps[0];
  }

  renderBody() {
    const { deal, item, droppedFile } = this.props;
    const { du, file, description, saving, dateType, lastModified, error, errorInfo, attachmentType, title, variable } =
      this.state;

    const isVariableAttachment = attachmentType === ATTACHMENT_TYPE.VARIABLE;

    return (
      <>
        <Modal.Body>
          {this.isNew && !deal.hasVersions && this.isVersion && (
            <div className="wrapper" data-cy="alert-content">
              <Alert centered>Uploading a new version will disable online editing for this {dt}</Alert>
            </div>
          )}
          {error && (
            <div className="wrapper">
              <Alert dmpStyle="danger">{error}</Alert>
              {errorInfo && (
                <>
                  <br />
                  {errorInfo}
                </>
              )}
            </div>
          )}

          {this.isNew && (
            <div className="wrapper">
              <FormGroup>
                <ControlLabel>Select File</ControlLabel>
                <div className="contents">
                  <FileUp
                    file={droppedFile}
                    acceptedTypes={this.isVersion ? VERSION_TYPES : STORAGE_TYPES}
                    mergeType={MERGE_TYPE.OVERWRITE}
                    onParse={this.onParse}
                  />
                </div>
              </FormGroup>
            </div>
          )}
          {!this.isNew && this.isVersion && (
            <div className="wrapper">
              <FormGroup>
                <ControlLabel>Filename</ControlLabel>
                <div className="contents existing-version">
                  <FormControl disabled value={item.filename} />
                </div>
              </FormGroup>
            </div>
          )}

          {this.isNew && file && (
            <div className="wrapper">
              <FormGroup>
                <ControlLabel>Treat File As</ControlLabel>
                <div className="contents type">
                  <Dropdown
                    block
                    id="dd-attachment-uploader-type"
                    onSelect={this.onChangeAttachmentType}
                    title={attachmentType ? _.upperFirst(attachmentType) : 'Select Type'}
                    dataCyToggle="dd-attachment-uploader-type"
                  >
                    {_.map(FILE_OPTIONS, ({ type, info, disabled, title }) => {
                      return (
                        <MenuItem
                          eventKey={type}
                          info={info}
                          active={attachmentType === type}
                          disabled={disabled(this.isStorageOnly)}
                          data-cy="attachment-type"
                        >
                          {title}
                        </MenuItem>
                      );
                    })}
                  </Dropdown>
                </div>
              </FormGroup>
            </div>
          )}

          {isVariableAttachment && (
            <div className="wrapper">
              <FormGroup>
                <ControlLabel>Variable name</ControlLabel>
                <div className="contents variable">
                  <Dropdown
                    block
                    id="dd-attachment-uploader-variable"
                    onSelect={(variableID) => this.selectVariable(variableID)}
                    title={
                      variable?.displayName ? variable.displayName : variable?.name ? variable.name : 'Select Variable'
                    }
                  >
                    {_.map(this.imageVariables, ({ name, displayName }) => {
                      return (
                        <MenuItem eventKey={name} active={name === variable?.name}>
                          {displayName ? displayName : name}
                        </MenuItem>
                      );
                    })}
                  </Dropdown>
                  <div className="error">
                    {variable
                      ? `This will replace ${
                          variable.displayName ? variable.displayName : variable.name
                        } with the uploaded image. Are you sure?`
                      : ''}
                  </div>
                </div>
              </FormGroup>
            </div>
          )}

          {this.isStorage && (file || !this.isNew) && (
            <div className="wrapper">
              <FormGroup>
                <ControlLabel>Filename</ControlLabel>
                <div className="contents title">
                  <InputGroup>
                    <FormControl
                      value={title}
                      placeholder="e.g., Supporting materials"
                      onChange={(e) => this.setState({ title: e.target.value })}
                      data-cy="attachment-title"
                    />
                    <InputGroup.Addon>.{this.extension}</InputGroup.Addon>
                  </InputGroup>
                </div>
              </FormGroup>
            </div>
          )}

          {this.isVersion && (
            <div className="wrapper">
              <FormGroup>
                <ControlLabel>{Dt} Status</ControlLabel>
                <div className="contents deal-status">
                  <DealStatusSelector
                    steps={deal.workflow.steps}
                    enableAllSteps
                    onSelect={(step) => this.setState({ dealStatus: step.key })}
                    currentStep={this.workflowStep}
                  />
                </div>
              </FormGroup>
            </div>
          )}

          {(file || !this.isNew) && this.isVersion && (
            <div className="wrapper">
              <FormGroup>
                <ControlLabel>Attribution</ControlLabel>
                <div className="contents attribution">
                  <Dropdown
                    id="dd-attribution"
                    title={du ? du.get('fullName') : 'Select User'}
                    onSelect={(du) => this.setState({ du })}
                    dataCyToggle="dd-attribution"
                  >
                    {deal.users.map((u) => (
                      <MenuItem key={u.key} eventKey={u} data-cy="attribution-item">
                        {u.get('fullName')}
                      </MenuItem>
                    ))}
                  </Dropdown>
                  <small>Select the user who is responsible for this {dt} version.</small>
                </div>
              </FormGroup>
            </div>
          )}

          {file && attachmentType && this.isNew && (
            <div className="wrapper">
              <FormGroup>
                <ControlLabel>File Date</ControlLabel>
                <div className="contents date">
                  <Radio
                    name="dateType"
                    checked={dateType === 'file'}
                    disabled={saving}
                    onChange={this.onChangeDateType}
                    value="file"
                  >
                    Use file's last modified date ({this.displayDate(new Date(file.lastModified))})
                  </Radio>
                  <Radio
                    name="dateType"
                    checked={dateType === 'custom'}
                    disabled={saving}
                    onChange={this.onChangeDateType}
                    value="custom"
                  >
                    <span>Custom</span>
                    <DayTime
                      ref={this.refCustomDate}
                      disabled={dateType !== 'custom'}
                      hasTime={true}
                      onChange={this.updateDate}
                    />
                  </Radio>
                </div>
              </FormGroup>
            </div>
          )}

          {!this.isNew && (
            <div className="wrapper">
              <FormGroup>
                <ControlLabel>File Date</ControlLabel>
                <div className="contents date">
                  <DayTime ref={this.refCustomDate} date={lastModified} hasTime={true} onChange={this.updateDate} />
                </div>
              </FormGroup>
            </div>
          )}

          {attachmentType && (file || !this.isNew) && (
            <div className="wrapper">
              <FormGroup>
                <ControlLabel>Description</ControlLabel>
                <div className="contents description">
                  <FormControl
                    componentClass="textarea"
                    value={description}
                    placeholder="e.g., Final version for execution (optional)"
                    onChange={(e) => this.setState({ description: e.target.value })}
                    data-cy="attachment-description"
                  />
                </div>
              </FormGroup>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!this.isReady || saving}
            dmpStyle="primary"
            onClick={() => (this.isNew ? this.tryUpload() : this.update())}
            data-cy="btn-upload"
          >
            {this.isNew ? 'Upload' : 'Update'}
          </Button>
        </Modal.Footer>
      </>
    );
  }

  render() {
    const { show } = this.props;
    const { saving, error } = this.state;

    return (
      <Modal dialogClassName="deal-version-uploader" show={show} onHide={this.onClose} data-cy="attachment-uploader">
        <Modal.Header closeButton={this.canClose}>
          <span className="headline">{this.title}</span>
        </Modal.Header>

        {saving && error ? this.renderError() : saving && this.isNew ? this.renderSaving() : this.renderBody()}
      </Modal>
    );
  }
}
