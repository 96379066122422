import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ButtonGroup, FormControl, FormGroup } from 'react-bootstrap';

import SectionType, {
  HEADER_FOOTER_CONFIG_OPTIONS,
  HEADER_FOOTER_CONFIG_TYPES,
  HEADER_FOOTER_NUMBERING,
} from '@core/enums/SectionType';
import { LIST_TYPES } from '@core/models/List';
import Section from '@core/models/Section';
import { FEATURES } from '@core/models/Team';
import { FONT_SIZES, LINE_SPACINGS, THEME_FONTS } from '@core/models/TypeStyle';
import VariableFilter from '@core/models/VariableFilter';
import { dt } from '@core/utils';
import stateFromHTML from '@core/utils/StateFromHTML';

import { Button, Dropdown, Form, Icon, Loader, MenuItem, Switch } from '@components/dmp';
import Alert from '@components/dmp/Alert';

import ColorPicker from '@components/ColorPicker';
import EmptyState from '@components/EmptyState';
import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import CaptionSidebar from '@components/editor/CaptionSidebar';
import CellSidebar from '@components/editor/CellSidebar';
import ConditionEditor from '@components/editor/ConditionEditor';
import ConditionsView from '@components/editor/ConditionsView';
import ListSidebar from '@components/editor/ListSidebar';
import SectionSuggest from '@components/editor/SectionSuggest';
import TemplateLink from '@components/editor/TemplateLink';
import TooltipButton from '@components/editor/TooltipButton';
import API from '@root/ApiClient';
import Fire from '@root/Fire';
import VariableIndex from '@root/utils/VariableIndex';

const SECTION_MODES = {
  UNFOCUSED: 'unfocused',
  SEARCH: 'search',
  LINKED: 'linked',
  UNLINKED: 'unlinked',
  NOINDEX: 'noindex',
};

const TABS = [
  { key: 'general', title: 'General' },
  { key: 'format', title: 'Format' },
];

const LIBRARY_TITLES = {
  [SectionType.SOURCE]: 'Clause Library',
  [SectionType.TEMPLATE_HEADER]: 'Header Library',
  [SectionType.TEMPLATE_FOOTER]: 'Footer Library',
};

const LIBRARY_INFO = {
  [SectionType.SOURCE]: 'Set a label for this section in order to make it searchable in your clause libaray.',
  [SectionType.TEMPLATE_HEADER]: 'Please add a name to index this header for reuse.',
  [SectionType.TEMPLATE_FOOTER]: 'Please add a name to index this footer for reuse.',
};

const LOOKUP_INFO = {
  [SectionType.SOURCE]:
    'This section is not indexed because it has no body. Draft manually, or search your library for a clause to reuse.',
  [SectionType.TEMPLATE_HEADER]:
    'This header is not indexed because it has no name or body. Draft manually, or search your library for a header to reuse by entering its name.',
  [SectionType.TEMPLATE_FOOTER]:
    'This footer is not indexed because it has no name or body. Draft manually, or search your library for a header to reuse by entering its name.',
};

const getLibraryInfo = ({ sectiontype, displayname }) => {
  if (sectiontype === SectionType.SOURCE && displayname) {
    return 'This section is searchable in your clause library by its title';
  }

  return LIBRARY_INFO[sectiontype] || 'Please add a name to index this item for reuse.';
};

@autoBindMethods
export default class SectionSidebar extends Component {
  static propTypes = {
    section: PropTypes.instanceOf(Section),
    onSelectCL: PropTypes.func.isRequired,
    variableIndex: PropTypes.instanceOf(VariableIndex),
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      indexCL: '',
      linkedRecord: null,
      linkedConditions: [],
      search: '',
      searchResults: {},
      loading: false,
      tab: TABS[0],
      headerFooterConfigKey: null,
      pageBreakID: null,
    };

    this.refCL = createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    this.populate(this.props.section);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.section !== this.props.section) {
      this.populate(this.props.section);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  populate(section) {
    this.setState({
      indexCL: _.get(this.topLevelSection, 'indexCL', ''),
      vitals: _.get(section, 'vitals', ''),
      headerFooterConfigKey: section?.isTemplateHeaderFooterSubSection
        ? _.get(section, 'sourceParent.headerFooterConfigKey', HEADER_FOOTER_CONFIG_OPTIONS.NO_PAGES)
        : _.get(section, 'headerFooterConfigKey', HEADER_FOOTER_CONFIG_OPTIONS.NO_PAGES),
      pageBreakID: section?.isTemplateHeaderFooterSubSection
        ? _.get(section, 'sourceParent.pageBreakID', null)
        : _.get(section, 'pageBreakID', null),
    });
    // If the latest section is linked, we need another API call to lookup
    if (_.get(section, 'originCL')) {
      this.getLinkedSection(section.originCL);
    } else {
      this.setState({ linkedRecord: null });
    }
  }

  reset() {
    this.setState({
      indexCL: '',
      linkedRecord: null,
      linkedConditions: [],
      search: '',
      searchResults: {},
      loading: false,
      vitals: '',
      headerFooterConfigKey: null,
      pageBreakID: null,
    });
  }

  get topLevelSection() {
    const { section } = this.props;
    return section?.isTemplateHeaderFooterSubSection ? section.sourceParent : section;
  }

  async getLinkedSection(sectionRecordID) {
    try {
      const result = await API.call('getMetaCL', { sectionRecordID });
      let linkedConditions = [];

      if (result && result.conditions) {
        linkedConditions = _.map(result.conditions, (con) => new VariableFilter(con.variable, con.values));
      }

      this.setState({
        linkedRecord: result || null,
        linkedConditions,
      });
    } catch (e) {
      // We get here if the user is not on the team that the linked clause is from
      this.setState({
        linkedRecord: null,
        linkedConditions: [],
      });
    }
  }

  get mode() {
    const { section } = this.props;

    if (!section) {
      return SECTION_MODES.UNFOCUSED;
    } else if (section.sectiontype === SectionType.HEADER) {
      return SECTION_MODES.NOINDEX;
    } else if (section.originCL) {
      return SECTION_MODES.LINKED;
    } else if (section.content) {
      // content can sometimes be populated (by DraftJS) even though it's actually blank
      // so we want to still enable clause lookup in that instance
      const cs = stateFromHTML(section.content);
      if (cs.getPlainText().trim() === '') {
        return SECTION_MODES.SEARCH;
      } else {
        return SECTION_MODES.UNLINKED;
      }
    } else {
      return SECTION_MODES.SEARCH;
    }
  }

  get showCL() {
    const { section } = this.props;
    return section && section.isTemplate && section.canCL;
  }

  get showConditions() {
    const { section } = this.props;
    return section && section.subType !== LIST_TYPES.REPEATER;
  }

  get headerFooterConfig() {
    const { section } = this.props;
    const { isTemplateHeader, deal } = section;

    return _.map(deal.headerFooterConfig, (config) => {
      const { key, id } = config;
      const sourceSection = _.find(isTemplateHeader ? deal.headerSections : deal.footerSections, {
        headerFooterConfigKey: key,
        pageBreakID: id,
      });
      if (key !== HEADER_FOOTER_CONFIG_OPTIONS.NO_PAGES && sourceSection && sourceSection.conditions.length === 0) {
        config.disabled = true;
      } else {
        config.disabled = false;
      }
      return config;
    });
  }

  get showHeaderFooterConfig() {
    const { section } = this.props;
    return section && !section.isCaption && section.isTemplateHeaderFooter;
  }

  handleSearchChange(e) {
    const search = e.target.value;
    this.setState({ search });
  }

  onSearchKeyDown(e) {
    const clSuggest = this.refCL.current;
    if (clSuggest && clSuggest.handleKey(e)) {
      e.preventDefault();
    }
  }

  toggleExcludeCL() {
    const { section } = this.props;

    Fire.saveSection(section, { excludeCL: section.excludeCL ? null : true });
  }

  toggleClauseExtraction() {
    const { section } = this.props;

    Fire.saveSection(section, { extract: !section.extract });
  }

  updateIndexCL(e) {
    const titleCL = e.target.value;
    this.setState({ indexCL: titleCL });
    this.saveIndexCL(titleCL);
  }

  saveIndexCL = _.debounce((titleCL) => {
    if (titleCL.trim() === '') titleCL = null;
    Fire.saveSection(this.topLevelSection, { titleCL });
  }, 500);

  updateVitals(e) {
    const vitals = e.target.value;
    this.setState({ vitals });
    this.saveVitals(vitals);
  }

  saveVitals = _.debounce((vitals) => {
    const { section } = this.props;
    if (!vitals.trim()) vitals = null;
    Fire.saveSection(section, { vitals });
  }, 500);

  async saveSectionStyle(id, value, type) {
    let section = this.headerFooterSection;
    const style = {};
    style[id] = value;

    await Fire.saveSectionStyle(section, style, false, type);
  }

  //Returns the parent of the two column config
  get headerFooterSection() {
    const section = this.props.section.isTemplateHeaderFooterSubSection
      ? this.props.section.sourceParent
      : this.props.section;

    return section;
  }

  async updateHeaderFooterConfigKey(headerFooterConfig) {
    const { key, id } = headerFooterConfig;
    const { NO_PAGES, ALL_PAGES, FIRST_PAGE } = HEADER_FOOTER_CONFIG_OPTIONS;
    const section = this.headerFooterSection;
    if (key === NO_PAGES || key === ALL_PAGES || key === FIRST_PAGE) {
      await Fire.saveSection(section, { headerFooterConfigKey: key, pageBreakID: null });
      this.setState({ headerFooterConfigKey: key });
    } else {
      await Fire.saveSection(section, { headerFooterConfigKey: key, pageBreakID: id });
      this.setState({ headerFooterConfigKey: key });
    }
  }

  async updateHeaderFooterNumbering(key) {
    const section = this.headerFooterSection;
    await Fire.saveSection(section, { headerFooterNumbering: key });
  }

  toggleDateStamps(showDates) {
    Fire.saveSection(this.props.section, { showDates });
  }

  renderConditions() {
    const section = this.headerFooterSection;
    return (
      <DealPanelItem borderBottom>
        <div className="property-title">Conditions</div>
        <ConditionEditor section={section} onUpdate={_.noop} />
      </DealPanelItem>
    );
  }

  renderNumberingGroups(type, idx) {
    const section = this.headerFooterSection;
    const groups = _.filter(HEADER_FOOTER_NUMBERING, { type });

    const items = [];

    if (idx !== 0) items.push(<MenuItem divider />);
    _.map(groups, (numbering) => {
      items.push(
        <MenuItem
          key={`${numbering.key}|${numbering.type}`}
          eventKey={`${numbering.key}|${numbering.type}`}
          active={section.headerFooterNumbering.title === numbering.title}
          data-cy="header-footer-numbering-option"
        >
          {numbering.title}
        </MenuItem>
      );
    });

    return items;
  }

  renderHeaderFooterConfig() {
    const { deal } = this.props.section;
    const section = this.headerFooterSection;
    const { headerFooterConfigKey, pageBreakID } = this.state;
    const selectedHeaderFooterConfig = headerFooterConfigKey
      ? deal.headerFooterConfig.find((config) => {
          if (config.key === headerFooterConfigKey) {
            if (config.id) {
              return config.id === pageBreakID;
            } else return true;
          }
        })
      : null;

    const numberingGroups = _.uniq(
      _.map(HEADER_FOOTER_NUMBERING, (numbering) => {
        return numbering.type;
      })
    );

    const sectionTypeText = section.sectiontype === SectionType.TEMPLATE_FOOTER ? 'footer' : 'header';

    return (
      <div className="section-header-footer-config-item" data-cy="section-header-footer-config">
        <Form className="section-header-footer-config-editor">
          <div className="property-title" data-cy="property-title">
            {section.displayname}
          </div>
          <div className="row-group">
            <div className="header-footer-item dropdown">
              <div className="item-label">{`Print ${sectionTypeText} on`}</div>
              <Dropdown
                id="dse-dd-header-footer-print-on"
                onSelect={this.updateHeaderFooterConfigKey}
                title={selectedHeaderFooterConfig?.displayName || 'No pages'}
                size="small"
                block
                dataCyToggle="header-footer-page"
              >
                {HEADER_FOOTER_CONFIG_TYPES.map((type) =>
                  this.renderHeaderFooterConfigOptions(type, selectedHeaderFooterConfig)
                )}
              </Dropdown>
            </div>
            <div className="header-footer-item dropdown pageCount">
              <div className="item-label">Page Count</div>
              <Dropdown
                id="dse-dd-header-footer-print-on"
                onSelect={this.updateHeaderFooterNumbering}
                title={section.headerFooterNumbering.title}
                size="small"
                block
                disabled={section.isDefault}
                dataCyToggle="header-footer-count"
              >
                {numberingGroups.map(this.renderNumberingGroups)}
              </Dropdown>
            </div>
          </div>
          <small>Insert page count by typing "[" within header or footer</small>
        </Form>
      </div>
    );
  }

  renderHeaderFooterConfigOptions(type, selectedHeaderFooterConfig) {
    const groups = _.filter(this.headerFooterConfig, { type });

    const items = [];

    _.map(groups, (config, idx) => {
      if (idx === 0 && type !== 'default') items.push(<MenuItem divider />);
      items.push(
        <MenuItem
          key={config.key === 'pageBreak' ? config.id : config.key}
          eventKey={config}
          active={config?.displayName === selectedHeaderFooterConfig?.displayName}
          disabled={config.disabled}
          data-cy="header-footer-config-option"
        >
          {config.key === 'pageBreak' ? `From ${_.upperFirst(config.displayName)}` : config.displayName}
        </MenuItem>
      );
    });

    return items;
  }

  renderUnfocused() {
    return <EmptyState title="No section selected" subtitle="Click on a section to view its properties" />;
  }

  renderNoIndex() {
    return (
      <DealPanelItem borderBottom disabled>
        <div className="property-title">Clause Library</div>
        <div className="property-info">Headers are not indexed in your clause library</div>
      </DealPanelItem>
    );
  }

  renderSearch() {
    const { section, onSelectCL } = this.props;
    const { search } = this.state;

    const noResults = _.get(this.refCL.current, 'noResults');
    const loading = _.get(this.refCL.current, 'loading');
    const title = LIBRARY_TITLES[section.sectiontype] || 'Library';
    const lookupInfo =
      LOOKUP_INFO[section.sectiontype] ||
      'This item is not indexed because it has no body. Draft manually, or search your library for one to reuse.';

    return (
      <>
        <DealPanelItem borderBottom className="cl-search">
          <div className="property-title">{title}</div>

          <div className="item-label">Lookup</div>
          <FormGroup>
            <FormControl
              onKeyDown={this.onSearchKeyDown}
              onChange={this.handleSearchChange}
              placeholder="e.g., Indemnification"
              type="text"
              value={search}
              bsSize="small"
              data-cy="input-search-cl"
            />
            {loading && <Loader />}
          </FormGroup>

          <div className="item-sub">{lookupInfo}</div>
        </DealPanelItem>

        {noResults && !loading && (
          <DealPanelItem borderBottom disabled>
            <div className="property-title">No clauses matched your search.</div>
            <div className="property-info">Ensure that the clause you're trying to reference is indexed.</div>
          </DealPanelItem>
        )}

        <SectionSuggest
          ref={this.refCL}
          teamID={section.deal.team}
          input={search}
          onSelect={onSelectCL}
          onResults={(searchResults) => this.setState({ searchResults })}
          section={section}
        />
      </>
    );
  }

  renderLinked() {
    const { indexCL, linkedRecord, linkedConditions } = this.state;

    return (
      <DealPanelItem borderBottom>
        <div className="property-title">Clause Library</div>
        <div className="item-label">Source</div>
        <FormGroup>
          <FormControl disabled type="text" value={indexCL} onChange={this.updateIndexCL} bsSize="small" />
        </FormGroup>
        <div className="item-sub">This section is dynamically linked to another section in your clause library.</div>
        {linkedRecord && (
          <TemplateLink title={linkedRecord.displayTemplate || linkedRecord.template} dealID={linkedRecord.dealID} />
        )}
        {linkedConditions.length > 0 && <ConditionsView conditions={linkedConditions} />}
      </DealPanelItem>
    );
  }

  renderUnlinked() {
    const { section } = this.props;
    const { indexCL } = this.state;

    const title = LIBRARY_TITLES[section.sectiontype] || 'Library';
    const libraryInfo = getLibraryInfo({
      sectiontype: section.sectiontype,
      displayName: this.topLevelSection.displayname,
    });

    return (
      <DealPanelItem borderBottom>
        <div className="property-title" data-cy="property-title">
          {title}
        </div>
        <div className="item-label">Title</div>
        <FormGroup>
          <FormControl
            disabled={
              (!!this.topLevelSection.displayname && !section.isTemplateHeaderFooter) || this.topLevelSection.excludeCL
            }
            placeholder="e.g., Term"
            type="text"
            value={indexCL}
            onChange={this.updateIndexCL}
            bsSize="small"
          />
        </FormGroup>
        <div className="item-sub">{libraryInfo}</div>

        <Switch
          className="chk-index"
          id="chk-index-section-cl"
          checked={!this.topLevelSection.excludeCL && !!this.topLevelSection.indexCL}
          disabled={!this.topLevelSection.indexCL}
          onChange={this.toggleExcludeCL}
          data-cy="cl-indexed-check"
          size="small"
        >
          Indexed
        </Switch>
        {section.sectiontype === SectionType.SOURCE && (
          <div className="item-sub">All sections are searchable by default</div>
        )}
      </DealPanelItem>
    );
  }

  renderMode() {
    switch (this.mode) {
      case SECTION_MODES.SEARCH:
        return this.renderSearch();
      case SECTION_MODES.LINKED:
        return this.renderLinked();
      case SECTION_MODES.UNLINKED:
        return this.renderUnlinked();
      case SECTION_MODES.NOINDEX:
        return this.renderNoIndex();
      default:
        return null;
    }
  }

  renderModelError() {
    const { section } = this.props;
    return (
      <DealPanelItem borderBottom data-cy="section-error" className="section-error">
        <div className="property-title" data-cy="property-title">
          Errors
        </div>
        <Alert dmpStyle="danger">{section.modelError.tip}</Alert>
      </DealPanelItem>
    );
  }

  renderGeneralEditor() {
    const { section, variableIndex, team, user } = this.props;

    return (
      <>
        {!section && this.renderUnfocused()}
        {section && section.modelError && this.renderModelError()}
        {this.showCL && this.renderMode()}
        {this.showHeaderFooterConfig && this.renderHeaderFooterConfig()}
        {section && section.sectiontype === SectionType.SIGNATURE && this.renderDateStamp()}
        {this.showConditions && this.renderConditions()}
        {section && section.isCaption && <CaptionSidebar caption={section.layoutSection} />}
        {section && section.isCell && <CellSidebar section={section} />}
        {section && section.isList && <ListSidebar list={section} variableIndex={variableIndex} user={user} />}
        {/*section &&
          section.sectiontype === SectionType.SOURCE &&
          team &&
          team.isFeatureActive(FEATURES.VITALS) &&
    this.renderVitals()*/}
        {section &&
          section.sectiontype === SectionType.SOURCE &&
          team &&
          team.isFeatureActive(FEATURES.PDF_EXTRACTION) &&
          section.deal.template &&
          section.deal.template.documentAI &&
          this.renderExtraction()}
      </>
    );
  }

  renderDateStamp() {
    const { section } = this.props;
    return (
      <DealPanelItem borderBottom data-cy="date-stamp-setting">
        <div className="property-title" data-cy="property-title">
          Settings
        </div>
        <Switch
          id={`check-date-${section.id}`}
          checked={section.showDates}
          onChange={() => this.toggleDateStamps(!section.showDates)}
          size="small"
          data-cy="date-stamp"
        >
          Show date stamp below signatures
        </Switch>
      </DealPanelItem>
    );
  }

  renderVitals() {
    const { vitals } = this.state;

    return (
      <DealPanelItem borderBottom>
        <div className="property-title" data-cy="property-title">
          Lens
        </div>

        <div className="item-label">Variants</div>
        <FormGroup>
          <FormControl
            placeholder="e.g., Confidentiality"
            componentClass="textarea"
            value={vitals}
            onChange={this.updateVitals}
            bsSize="small"
          />
        </FormGroup>
        <div className="item-sub">
          Enter one title variant per line for this vital. These clause names will be used to match against third-party{' '}
          {dt}s to identify areas that require attention during {dt} review.
        </div>
      </DealPanelItem>
    );
  }

  renderExtraction() {
    const { section } = this.props;

    return (
      <DealPanelItem borderBottom>
        <div className="property-title" data-cy="property-title">
          Document AI
        </div>
        <Switch id="extract-clause" checked={section.extract} onChange={this.toggleClauseExtraction} size="small">
          Enable Clause Extraction
        </Switch>
      </DealPanelItem>
    );
  }

  renderTabs() {
    const { tab } = this.state;

    return (
      <div className="filter-bar">
        <ButtonGroup className="panel-tabs element-types" data-cy="element-types">
          {TABS.map((option, idx) => (
            <Button
              dmpStyle="link"
              active={option.key === tab.key}
              onClick={() => this.setState({ tab: option })}
              key={idx}
              data-cy={`btn-${option.key}`}
            >
              {option.title}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    );
  }

  renderStyleEditor(type) {
    //returns root section of header footer subsections or just the section prop
    let section = this.headerFooterSection;

    const sectionStyle = type.isTitle ? section.styleTitle : section.styleBody;
    const defaultStyle = section.deal._style.type[type.key];
    const isDefault = _.isEqual(defaultStyle.raw.native, sectionStyle.raw.native);
    if (type.isTitle && !this.props.section.displayname) return;
    if (!type.isTitle && !this.props.section.hasBodyText) return;

    return (
      <div className="section-style-item" key={type.key}>
        <Form className="section-style-editor" data-cy="section-style-editor">
          <div className="row-group">
            <div className="style-item title">
              <span>
                {type.title}
                {!isDefault && (
                  <TooltipButton tip="Modified from theme default" className="info">
                    <span className="icon">
                      <Icon name="info" />
                    </span>
                  </TooltipButton>
                )}
              </span>
            </div>
            {!isDefault && (
              <div className="style-item reset">
                <Button
                  key={'reset'}
                  size="small"
                  dmpStyle="link"
                  onClick={() => Fire.deleteSectionStyle(section, false, type.key)}
                  data-cy="btn-reset-style-item"
                >
                  Reset
                </Button>
              </div>
            )}
          </div>
          <div className="row-group">
            <div className="style-item font">
              <div className="item-label">Font</div>
              <Dropdown
                id="dse-dd-font"
                onSelect={(font) => this.saveSectionStyle('font', font, type.key)}
                title={sectionStyle.selectedFont.displayName}
                size="small"
                block
                dataCyToggle="dse-dd-font"
              >
                {_.map(THEME_FONTS, (font, key) => (
                  <MenuItem key={key} eventKey={font.key}>
                    {font.displayName}
                  </MenuItem>
                ))}
              </Dropdown>
            </div>
            <div className="style-item font-size">
              <div className="item-label">Font Size</div>
              <Dropdown
                id="dse-dd-size"
                onSelect={(size) => this.saveSectionStyle('size', size, type.key)}
                title={`${sectionStyle.selectedSize.label} pt`}
                block
                size="small"
                dataCyToggle="dse-dd-size"
              >
                {_.map(FONT_SIZES, (size, idx) => (
                  <MenuItem key={idx} eventKey={size.value}>
                    {size.label} pt
                  </MenuItem>
                ))}
              </Dropdown>
            </div>
          </div>
          <div className="row-group">
            <div className="style-item color">
              <div className="item-label">Color</div>
              <ColorPicker
                style="compact"
                color={_.get(sectionStyle, 'native.color', 'black')}
                onChange={(color) => this.saveSectionStyle('color', color, type.key)}
                size="small"
              />
            </div>
            <div className="style-item line-spacing">
              <div className="item-label">Line Spacing</div>
              <Dropdown
                id="dse-dd-ls"
                onSelect={(lineSpacing) => this.saveSectionStyle('lineSpacing', lineSpacing, type.key)}
                title={sectionStyle.selectedLineSpacing.label}
                block
                size="small"
                dataCyToggle="dse-dd-ls"
              >
                {_.map(LINE_SPACINGS, (sp, idx) => (
                  <MenuItem key={idx} eventKey={sp.value}>
                    {sp.label}
                  </MenuItem>
                ))}
              </Dropdown>
            </div>
          </div>
        </Form>
      </div>
    );
  }

  renderFormatEditor() {
    const { section } = this.props;
    return (
      <>
        {!section && this.renderUnfocused()}
        {section &&
          _.map(section.styleTypes, (type) => {
            return this.renderStyleEditor(type);
          })}
      </>
    );
  }

  render() {
    const { tab } = this.state;
    const { section } = this.props;

    return (
      <div className="section-sidebar panel-scroll" data-cy="section-sidebar">
        {section && this.renderTabs()}
        {tab.key === 'general' && this.renderGeneralEditor()}
        {tab.key === 'format' && this.renderFormatEditor()}
      </div>
    );
  }
}
